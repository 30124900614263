<template>
  <div class="container">
    <mdb-row>
      <mdb-col sm="12" md="12" lg="12">
        <div class="card px-4">
          <mdb-row>
            <mdb-col sm="12" md="8" lg="8" style="display: flex; align-items: center">
              <h5 style="font-weight: 500">{{ $router.history.current.meta.sub }}</h5>
            </mdb-col>

            <mdb-col sm="12" md="4" lg="4">
              <mdb-select @getValue="getPage" v-model="option" outline></mdb-select
            ></mdb-col>
          </mdb-row>
        </div>
      </mdb-col>
      <mdb-col sm="12" md="12" lg="12">
        <router-view></router-view>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mdbSelect, mdbRow, mdbCol } from "mdbvue";
export default {
  components: {
    mdbSelect,
    mdbRow,
    mdbCol,
  },
  data() {
    return {
      page: "Quantité vendue par article",
      option: [
        {
          text: "Quantité vendue par article",
          value: "/reports/quantities/article",
          selected: true,
        },
        {
          text: "Quantité vendue par article et par site",
          value: "/reports/quantities/article/site",
        },
      ],
    };
  },

  methods: {
    getPage(v, t) {
      this.page = t;
      this.$router.push(v);
    },
  },
};
</script>
